const CSS_CLASSES = {
	MENU: 'menu-horizontal',
	MENU_ITEM: 'menu-horizontal__item',
	MENU_ITEM_WITH_DOTS: 'menu-horizontal__item--dots',
	MENU_DROPDOWN: 'menu-horizontal__dropdown'
};

export function horizontalMenuResizer(element)
{
	element.style.oveflow = 'hidden';

	const menuRect = element.getBoundingClientRect();
			
	const dots = element.querySelector(`.${CSS_CLASSES.MENU_ITEM_WITH_DOTS}`);
	dots.style.display = 'block';
	const dotsDropdown = dots.querySelector(`.${CSS_CLASSES.MENU_DROPDOWN}`) || document.createElement('div');
	const dotsRect = dots.getBoundingClientRect();
	while(dotsDropdown.firstChild) 
		dotsDropdown.removeChild(dotsDropdown.lastChild);

	const items = Array.prototype.slice.call(element.children, 0)
		.filter(item => !item.classList.contains(CSS_CLASSES.MENU_ITEM_WITH_DOTS));

	let needDots;

	for (const i in items)
	{ 
		const item = items[i];
		item.style.display = 'block';

		const itemRect = item.getBoundingClientRect();
		needDots = i < items.length - 1;

		if (
			(needDots && itemRect.right + dotsRect.width > menuRect.right) ||
			(!needDots && itemRect.right > menuRect.right)
		)
		{
			items
				.slice(i)
				.forEach(item => {
					const cloneItem = item.cloneNode(true);
					dotsDropdown.appendChild(cloneItem);

					item.style.display = 'none';
				});

			break;
		}
	}

	if (!needDots)
		dots.style.display = 'none';

	element.style.oveflow = 'visible';
}